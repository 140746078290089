import React, { CSSProperties, FC, ReactNode, Ref, useEffect, useState } from 'react';

import { useStyles } from './styles';

interface EditableProperties {
  text: string;
  node?: ReactNode;
  type: string;
  children: ReactNode;
  childRef: Ref<unknown>;
  style?: CSSProperties;
  hint?: ReactNode;
  prefix?: ReactNode;
  editableInput?: boolean;
  onClose?: (text: string) => void;
  editMode?: boolean;
}

export const Editable: FC<EditableProperties> = ({
  text,
  node,
  type,
  children,
  childRef,
  hint,
  prefix,
  onClose,
  editMode,
  editableInput = false,
  ...props
}) => {
  const classes = useStyles();
  const [isEditing, setEditing] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (childRef && childRef.current && isEditing === true) {
      // @ts-ignore
      childRef.current.focus();
    }
  }, [isEditing, childRef]);

  const handleKeyDown = (event: KeyboardEvent, eventType: string): void => {
    const { key } = event;
    const keys = ['Escape', 'Tab'];
    const enterKey = 'Enter';
    const allKeys = [...keys, enterKey];

    if ((eventType === 'textarea' && keys.includes(key)) || (eventType !== 'textarea' && allKeys.includes(key))) {
      setEditing(false);
      if (onClose) {
        onClose(text);
      }
    }
  };

  const clickHandler = (): void => setEditing(true);

  return (
    <span {...props} className={`${editableInput ? classes.editableInputSpan : ''} `}>
      {editMode || isEditing ? (
        // @ts-ignore
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <span onBlur={() => setEditing(false)} onKeyDown={(event) => handleKeyDown(event, type)}>
          {children}
        </span>
      ) : (
        <>
          {prefix}
          <span
            role="switch"
            aria-checked="false"
            aria-labelledby="Edit"
            className={editableInput ? classes.editableInputStyle : classes.editable}
            onClick={clickHandler}
            onKeyDown={() => setEditing(true)}
            title="Edit"
            tabIndex={-1}
          >
            {node || text || 'Editable content'}
          </span>
          {hint}
        </>
      )}
    </span>
  );
};

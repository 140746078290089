import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import NoSsr from '@material-ui/core/NoSsr';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { signIn } from 'next-auth/client';
import { useSnackbar } from 'notistack';
import React, { FC, SyntheticEvent, useEffect } from 'react';

import { snackbarOptions } from '../../../config';
import { useStyles } from './styles';

interface SignInProperties {
  token?: string;
}

export const SignIn: FC<SignInProperties> = ({ token }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault();

    await signIn('google', { callbackUrl: '/' });
  };

  useEffect(function () {
    const parameters = new URLSearchParams(window.location.search);
    const errorType = parameters.get('error');

    if (errorType) {
      const message =
        errorType === 'CredentialsSignin' ? 'Sign in failed. Check the details you provided are correct.' : errorType;

      enqueueSnackbar(message, {
        ...snackbarOptions.error,
      });
    }
  }, []);

  return (
    <NoSsr>
      <Paper variant="outlined" square elevation={4} className={classes.root}>
        <FormControl variant="filled" size="small">
          <form id="signin" method="post" action="/api/auth/callback/credentials">
            <input name="csrfToken" type="hidden" value={token} />
            <Typography variant="h4" gutterBottom>
              Login
            </Typography>
            <Button variant="contained" color="primary" className={classes.submitButton} onClick={onSubmit}>
              Sign in with Google
            </Button>
          </form>
        </FormControl>
      </Paper>
    </NoSsr>
  );
};

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { FC } from 'react';

import { useStyles } from './styles';

interface SnackProperties {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Snack: FC<SnackProperties> = ({ onClick }) => {
  const classes = useStyles();

  return (
    <IconButton aria-label="Close" onClick={onClick}>
      <CloseIcon fontSize="small" className={classes.icon} />
    </IconButton>
  );
};

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    editable: {
      display: 'inline-block',
      border: '1px solid transparent',

      '&:hover, &:focus': {
        borderColor: theme.palette.grey.A100,
      },
    },
    editableInputStyle: {
      minWidth: '60px',
      width: 'max-content',
      textAlign: 'right',
      border: '1px solid #DDDDDD',
      backgroundColor: '#FFFFFF',
      padding: '0 2px',
      borderRadius: '6px',
    },
    editableInputSpan: {
      alignItems: 'center',
      minWidth: '60px',
      width: '100%',
      textAlign: 'right',
      whiteSpace: 'nowrap',
    },
  };
});

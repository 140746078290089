import { makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 256;

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
    drawer: {
      [theme.breakpoints.up('lg')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    app: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    main: {
      flex: 1,
      padding: theme.spacing(6, 4),
      background: '#eaeff1',
    },
    footer: {
      padding: theme.spacing(2),
      background: '#eaeff1',
    },
    fit: {
      minHeight: 'calc(100vh - 88px)',
    },
    container: {
      marginTop: theme.spacing(2),
      marginLeft: '0px',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  };
});

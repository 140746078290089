export type Project = {
  id: number;
  name: string;
  billableType?: string;
  department?: string;
  parent?: {
    id: number;
    name?: string;
  };
};

export type Employee = {
  id: string;
  name: string;
  rate: number;
  salaryType: string;
  salary: number;
  stake?: number;
  employmentType?: number;
  rateV1?: number;
  rateV2?: number;
};

export interface FixBidCalculatorProperties {
  projects: Project[];
  employees: Employee[];
  overhead?: number;
  experiences: Record<string, number>;
  isStaging: boolean;
  syncStartDate: string;
}

export interface TargetCalculatorProperties {
  employees: Employee[];
  overhead?: number;
  experiences: Record<string, number>;
  isStaging: boolean;
}

export type TeamMembersConfig = {
  role: string;
  control: string;
};

export interface FixBidCalculatorConfigTypes {
  teamRoles: TeamMembersConfig[];
}

export enum RateType {
  euBillableHours = 'euBillableHours',
  usBillableHours = 'usBillableHours',
}

export type TeamMember = {
  id: string;
  role: string;
  control: string;
  employee?: Employee;
  rate?: number;
  customRate?: number;
  employeeName?: string;
  hours: {
    billable: number;
    absorbed?: number;
    total?: number;
  };
  salary?: number;
  billableSalary?: number;
  gtSalary?: number;
  rateType?: RateType;
};

export interface TeamMembersProperties {
  splitHours?: boolean;
  splitSalary?: boolean;
  team: TeamMember[];
  employees: Employee[];
  onCloneTeamMember: ({ teamMemberId }: Types.Store.Team.CloneMemberProperties) => void;
  onDeleteTeamMember: ({ teamMemberId }: Types.Store.Team.DeleteMemberProperties) => void;
  onChangeRole: ({ teamRoleId, role, defaultEmployee }: Types.Store.Team.ChangeRoleProperties) => void;
  onChangeCustomRate: ({ teamRoleId, teamRoleRate }: Types.Store.Team.SetRoleCustomRateProperties) => void;
  onChangeEmployee: ({ teamRoleId, employee }: Types.Store.Team.ChangeEmployeeProperties) => void;
  onChangeHours: ({ teamRoleId, part, hours }: Types.Store.Team.SetHoursProperties) => void;
  onRemoveCustomRate: ({ teamRoleId }: Types.Store.Team.RemoveRoleCustomRateProperties) => void;
  onSetRateType?: ({ teamMemberId, rateType }: Types.Store.Team.SetRateTypeProperties) => void;
  addTeamMember?: () => void;
  billableHours?: number;
  absorbedHours?: number;
  totalBillableSalary?: number;
  totalGTSalary?: number;
  totalHours: number;
  totalSalary: number;
  experiences: Record<string, number>;
  precision: number;
  needsRateType?: boolean;
}

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.common.white,
  },
  syncDate: {
    fontSize: 10,
    color: theme.palette.common.white,
  },
  synced: {
    backgroundColor: theme.palette.success.dark,
  },
  notSynced: {
    backgroundColor: theme.palette.error.dark,
  },
  animation: {
    animationDuration: '1s',
    animationName: '$spin',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
  '@keyframes spin': {
    from: { transform: 'rotate(360deg)' },
    to: { transform: 'rotate(0deg)' },
  },
}));

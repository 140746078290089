import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  option: {
    display: 'grid',
    gridTemplateColumns: 'minmax(50px, auto) min-content',
    columnGap: theme.spacing(0.5),
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  employeeSelectPaper: {
    minWidth: 300,
  },
  footer: {
    '& td': {
      fontSize: '1rem',
      fontWeight: 500,
      color: theme.palette.text.primary,
      borderBottom: 0,
    },
  },
  rateTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 4,
    fontSize: 12,
    padding: '1px 1px',
  },
  table: {
    display: 'grid',
    gridTemplateColumns: 'minmax(200px, 1fr) 2fr 100px 1fr 60px',
    minWidth: '100%',

    '& thead, & tbody, & tfoot, & tr': {
      display: 'contents',
    },
    '& th, & td': {
      padding: theme.spacing(0.4),
      paddingTop: theme.spacing(1.6),
      paddingBottom: theme.spacing(1.6),
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#555555',
    },
    '& th': {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.grey['100'],
      whiteSpace: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#9A9A9A',
    },
  },
  cell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tableWithSplitHours: {
    gridTemplateColumns: 'minmax(200px, 1fr) 50px minmax(200px, 1fr) 1fr 1fr 1fr 1fr 60px',
  },
  tableWithSplitSalary: {
    gridTemplateColumns:
      'minmax(200px, 1fr) 50px minmax(200px, 1fr) minmax(50px, 1fr) minmax(50px, 1fr) minmax(50px, 1fr) minmax(50px, 1fr) 60px',

    '& th, & td': {
      fontSize: 13,
    },
  },
  compactNumberFormat: {
    '& .MuiInputBase-input': {
      minWidth: 53,
      paddingLeft: theme.spacing(0.25),
      paddingRight: theme.spacing(0.25),
      textAlign: 'center',
    },
  },
  formControl: {
    flex: 1,
  },
  condensed: {
    '& .MuiOutlinedInput-inputMarginDense, & .MuiSelect-root': {
      padding: theme.spacing(1.4),
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
    },
  },
  selectWithRate: {
    '& * $option': {
      // marginRight: theme.spacing(1),
      gridTemplateColumns: 'minmax(100px, auto) 0',
    },
  },
  smallWithAddons: {
    '& .MuiInputBase-input': {
      padding: theme.spacing(0.85, 0, 0.85),
    },
  },
  textAlignRight: {
    '& .MuiOutlinedInput-input': {
      textAlign: 'right',
    },
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    backgroundColor: '#fff',
    boxShadow: '1px 4px 17px -2px rgba(0,0,0,0.98)',
    border: 'none',
    outline: 'none',
    borderRadius: 3,
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  listItemIcon: {
    minWidth: 30,
  },
  addTeamMemberButton: {
    '& .MuiButton-label': {
      color: theme.palette.primary.main,
    },
  },
  switchRoot: {
    marginTop: 'calc(50% - 12px)',
  },
}));

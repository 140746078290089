import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuButton from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import get from 'lodash.get';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { User } from 'next-auth';
import { signOut, useSession } from 'next-auth/client';
import React, { FC, useState } from 'react';

import { appTitle, Roles } from '../../../config';
import { version } from '../../../package.json';
import { SyncFlag } from '../SyncFlag';
import { useStyles } from './styles';

interface NavbarProperties {
  title?: string;
  onDrawerToggle: () => void;
}

export const Navbar: FC<NavbarProperties> = ({ title, onDrawerToggle }) => {
  const classes = useStyles();
  const router = useRouter();
  const [session] = useSession();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorElement(null);
  };
  const user: User | undefined = session?.user;
  let role = get(user, ['roles', 0], '');

  if (role === Roles.DepManager) {
    const departmentName = get(user, 'departmentName', '');

    role = `DM: ${departmentName}`;
  }

  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Toolbar>
        <Grid container alignContent="space-between" alignItems="center">
          <Hidden lgUp>
            <Grid item xs={false}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="Open menu"
                aria-haspopup
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item xs className={classes.logoContainer}>
            {router.pathname === '/' && (
              <Typography variant="h6">
                {appTitle}
                <sup className={classes.version}>{version}</sup>
              </Typography>
            )}
            {router.pathname !== '/' && (
              <NextLink href="/">
                <a>
                  <Typography variant="h6">
                    {title}
                    <sup className={classes.version}>{version}</sup>
                  </Typography>
                </a>
              </NextLink>
            )}
          </Grid>
          {user && (
            <Grid item xs={false}>
              <SyncFlag />
            </Grid>
          )}
          <Grid item xs={false}>
            {user && (
              <>
                <Button
                  aria-controls="profile-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  className={classes.topMenuItem}
                >
                  {user.name}
                </Button>
                {role && (
                  <Button aria-controls="profile-menu" aria-haspopup="true" className={classes.topMenuItem}>
                    {role}
                  </Button>
                )}

                <Menu
                  id="profile-menu"
                  anchorEl={anchorElement}
                  keepMounted
                  open={Boolean(anchorElement)}
                  onClose={handleClose}
                >
                  <MenuButton onClick={handleClose}>Profile</MenuButton>
                  <Divider />
                  <MenuButton onClick={() => signOut()}>Logout</MenuButton>
                </Menu>
              </>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

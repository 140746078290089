import { createMuiTheme } from '@material-ui/core/styles';

let theme = createMuiTheme({
  spacing: 10,
  // typography: {
  //   fontSize: 14,
  // },
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  drawerWidth: 240,
  overrides: {
    MuiGrid: {
      container: {
        // border: '1px solid red',
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#9A9A9A',
        paddingBottom: '4px',
        marginLeft: '4px',
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: 'white',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '14px',
      },
      inputMarginDense: {
        paddingTop: '14px',
        paddingBottom: '14px',
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&.MuiOutlinedInput-root.MuiOutlinedInput-marginDense': {
          padding: '10px',
        },
      },
    },
  },
};

export default theme;

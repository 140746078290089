import Divider from '@material-ui/core/Divider';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SyncIcon from '@material-ui/icons/Sync';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import clsx from 'clsx';
import get from 'lodash.get';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { User } from 'next-auth';
import { useSession } from 'next-auth/client';
import React, { FC } from 'react';

import { appTitle, isProduction, isStaging, Roles } from '../../../config';
import { version } from '../../../package.json';
import { useStyles } from './styles';

export type NavigationType = {
  id: string;
  showFor: string[];
  children: {
    id: string;
    icon: JSX.Element;
    href: string;
  }[];
}[];
export const navigation: NavigationType = [
  {
    id: 'Calculators',
    showFor: [Roles.Admin, Roles.DepManager, Roles.ProjectLead],
    children: [
      { id: 'Fixed Bid Calculator', icon: <ReceiptIcon />, href: '/calculators/fix-bid-calculator' },
      { id: 'Target Calculator', icon: <ReceiptIcon />, href: '/calculators/target-calculator' },
      {
        id: 'Target Calculator for current project',
        icon: <ReceiptIcon />,
        href: '/calculators/target-calculator-for-current-project',
      },
    ],
  },
  {
    id: 'Config',
    showFor: [Roles.Admin, Roles.User],
    children: [{ id: 'Employee', icon: <SupervisedUserCircleIcon />, href: '/config/employee' }],
  },
  {
    id: 'Tools',
    showFor: [Roles.Admin],
    children: [
      { id: 'Users', icon: <PeopleIcon />, href: '/tools/users' },
      { id: 'Synchronization status', icon: <SyncIcon />, href: '/tools/sync-state' },
      ...(!isProduction || isStaging
        ? [
            {
              id: 'Import Forecasting configs',
              icon: <SystemUpdateAltIcon />,
              href: '/tools/import-configs',
            },
          ]
        : []),
      { id: 'Settings', icon: <SettingsIcon />, href: '/tools/settings' },
    ],
  },
  {
    id: 'ProjectLeadTools',
    showFor: [Roles.ProjectLead, Roles.DepManager],
    children: [{ id: 'Synchronization status', icon: <SyncIcon />, href: '/tools/sync-state' }],
  },
];
export const Navigator: FC<DrawerProps> = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const [session] = useSession();
  const user: User | undefined = session?.user;
  const role = get(user, ['roles', 0], '');

  if (!session || !user) {
    router.push('/auth/signin');
  }

  return (
    <Drawer variant="permanent" {...props}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          {appTitle}
          <sup className={classes.version}>{version}</sup>
        </ListItem>
        <ListItem className={clsx(classes.item, classes.itemCategory)}>
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Overview
          </ListItemText>
        </ListItem>
        {navigation
          .filter(({ showFor }) => showFor.includes(role))
          .map(({ id, children }) => {
            return (
              <React.Fragment key={id}>
                <ListItem className={classes.categoryHeader}>
                  <ListItemText
                    classes={{
                      primary: classes.categoryHeaderPrimary,
                    }}
                  >
                    {id}
                  </ListItemText>
                </ListItem>
                {children.map(({ id: childId, icon, href }) => (
                  <NextLink href={`${href}`} key={childId}>
                    <ListItem
                      button
                      className={clsx(classes.item, href === router.pathname && classes.itemActiveItem)}
                      key={childId}
                    >
                      <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                      <ListItemText
                        classes={{
                          primary: classes.itemPrimary,
                        }}
                      >
                        {childId}
                      </ListItemText>
                    </ListItem>
                  </NextLink>
                ))}
                <Divider className={classes.divider} />
              </React.Fragment>
            );
          })}
      </List>
    </Drawer>
  );
};

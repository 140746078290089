import { useSession } from 'next-auth/client';
import { useEffect } from 'react';
import * as socketIOClient from 'socket.io-client';
import { Socket } from 'socket.io-client/build/socket';

let socket: Socket;

const connect = (token: string): Socket => {
  if (!socket) {
    // @ts-ignore
    socket = socketIOClient('', {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${token}`,
          },
        },
      },
    });
  }

  return socket;
};

const useSocket = <T = string>(eventName: string, callback: (data: T) => void): Socket => {
  const [session] = useSession();

  socket = connect(`${session && session.ioToken}`);

  useEffect(() => {
    socket.on(eventName, callback);

    return function useSocketCleanup() {
      socket.off(eventName, callback);
    };
  }, [eventName, callback]);

  return socket;
};

export default useSocket;

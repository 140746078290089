import { ForecastingRoleDocument } from '@server/models/forecasting/ForecastingRole';
import isEmpty from 'lodash.isempty';
import { LeanDocument } from 'mongoose';
import { Employee } from 'types/components/FixBidCalculator';

import { defaultSelectValue, SALARY_TYPE_V2 } from '../../config';

const filterByRole = (employees: Employee[], role: string): Employee[] => {
  const filteredEmployees = employees.filter((employee) => employee.roles?.includes(role));

  filteredEmployees.unshift({
    id: 'new',
    salaryType: SALARY_TYPE_V2,
    rate: 0,
    name: defaultSelectValue,
    department: [],
    roles: [],
    salary: 0,
  });

  return filteredEmployees;
};

const changeIdToNameRole = (dbRoles: LeanDocument<ForecastingRoleDocument>[], roles?: string[]): string[] | [] => {
  if (isEmpty(roles) || isEmpty(dbRoles)) {
    return [];
  }

  const filteredDbRoles = dbRoles.filter((role) => roles?.includes(role._id.toString()));

  return filteredDbRoles.map((role) => role.name);
};

export { changeIdToNameRole, filterByRole };

import { makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'sticky',
    top: 0,
    zIndex: 40,
    transitionProperty: 'all',
    transitionDuration: '150ms',
    flexGrow: 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logoContainer: {
    justifyContent: 'center',
    whiteSpace: 'nowrap',

    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-start',
    },

    '& a': {
      textDecoration: 'none',
      color: theme.palette.background.default,
    },

    '&:hover sup': {
      visibility: 'visible',
    },
  },
  drawer: {
    width: drawerWidth,
  },
  container: {
    padding: theme.spacing(0),
  },
  appBar: {
    // backgroundColor: theme.palette.background.default,
  },
  topMenuItem: {
    color: theme.palette.background.default,
  },
  version: {
    fontSize: 10,
    marginLeft: 3,
    visibility: 'hidden',
  },
}));

import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import Head from 'next/head';
import { useSession } from 'next-auth/client';
import React, { FC, ReactNode, useState } from 'react';

import { Navbar } from '../Navbar';
import { Navigator } from '../Navigator';
import { useStyles } from './styles';

interface LayoutProperties {
  children?: ReactNode;
  title?: string;
}

const drawerWidth = 256;

export const Layout: FC<LayoutProperties> = ({ title, children }) => {
  const classes = useStyles();
  const [session] = useSession();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={title} key="title" />
      </Head>
      <div id="page" className={classes.root}>
        {session && (
          <nav className={classes.drawer}>
            <Hidden lgUp implementation="js">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden mdDown implementation="css">
              <Navigator PaperProps={{ style: { width: drawerWidth } }} />
            </Hidden>
          </nav>
        )}
        <div className={classes.app}>
          <Navbar title={title} onDrawerToggle={handleDrawerToggle} />

          <main className={classes.fit}>
            <Container className={classes.container}>
              <div>{children}</div>
            </Container>
          </main>
        </div>
      </div>
    </>
  );
};

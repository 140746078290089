import IconButton from '@material-ui/core/IconButton';
import SyncIcon from '@material-ui/icons/Sync';
import clsx from 'clsx';
import get from 'lodash.get';
import React, { FC, useEffect, useState } from 'react';
import { forcedSignOut } from 'utils';

import useSocket from '../../../hooks/useSocket';
import { useStyles } from './styles';

export const SyncFlag: FC = () => {
  const classes = useStyles();
  const [date, setDate] = useState<string | Date>('');
  const [syncing, setSyncing] = useState(false);
  const [synced, setSynced] = useState<boolean | string>(true);
  const { BLOCK_SYNC_FLAG_UPDATE: updateBlocked } = process.env;

  let timerId: ReturnType<typeof setTimeout>;
  let stateTimerId: ReturnType<typeof setTimeout>;

  const fetchSyncState = async (): Promise<void> => {
    clearTimeout(stateTimerId);
    const request = await fetch('/api/status/sync');

    forcedSignOut(request.status);

    const data = await request.json();

    const result = get(data, 'synced', null);

    if (!result) {
      stateTimerId = setTimeout(() => {
        fetchSyncState();
      }, 1000 * 60 * 10);
    }

    return setSynced(result);
  };

  useEffect(() => {
    if (!updateBlocked) {
      fetchSyncState();
    }
  }, []);

  let previous = '';

  useSocket('message', (message) => {
    setSyncing(true);
    const newDate = get(message, 'data.spentOn', '');
    const action = get(message, 'action', '');

    if (newDate && previous !== newDate) {
      previous = newDate;
      setDate(new Date(newDate).toISOString().split('T')[0]);
      setSynced(false);
    }

    if (action === 'createRedmineIssue' && previous !== 'Sync Issues') {
      previous = 'Sync Issues';
      setDate(previous);
    }

    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      setSyncing(false);
      setDate('');
      if (!updateBlocked) {
        fetchSyncState();
      }
    }, 15000);
  });

  return (
    <>
      {date && syncing && <small className={classes.syncDate}>{date}</small>}
      <IconButton
        size="small"
        aria-label="Close"
        onClick={fetchSyncState}
        title="Current Sync Status"
        className={clsx({
          [classes.synced]: !syncing && synced === true,
          [classes.notSynced]: !syncing && synced === false,
          // [classes.syncProblem]: synced === 'problem',
        })}
      >
        <SyncIcon
          fontSize="small"
          className={clsx(classes.icon, {
            [classes.animation]: syncing,
          })}
        />
      </IconButton>
    </>
  );
};

import cn from 'classnames';
import React, { ReactElement } from 'react';
import NumberFormat from 'react-number-format';
import useColorChange from 'use-color-change';

import { colorChangeOptions, defaultDecimalScale } from '../../../config';
import { ShowNumberProperties } from '../../../types/components/common/ShowNumber';
import { useStyles } from './styles';

export const ShowNumber = ({
  number,
  suffix,
  prefix,
  style,
  condensed = false,
  withAnimation = false,
}: ShowNumberProperties): ReactElement => {
  const classes = useStyles();
  const decimalScale = condensed ? 0 : defaultDecimalScale;

  const colorStyle = useColorChange(number, colorChangeOptions);
  const numberStyle = withAnimation ? colorStyle : style;

  return (
    <NumberFormat
      value={number}
      style={numberStyle}
      displayType="text"
      thousandSeparator
      decimalScale={decimalScale}
      fixedDecimalScale
      suffix={suffix}
      prefix={prefix}
      className={cn({
        [classes.negative]: number < 0,
      })}
    />
  );
};
